export const fields = {
  applicationName: {
    label: 'Applications Name',
  },
  appService: {
    label: 'App Service',
    rules: 'required',
  },
  roles: {
    label: 'Roles',
    rules: 'required',
  },
  customer: {
    label: 'Customer',
  },
  sites: {
    label: 'Sites',
  },
};
  